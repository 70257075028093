import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { DemandeDeDocumentComplementaireService } from '@services-candidat/demandeDeDocumentComplementaire.service';
import { FicheDemandeAideService } from '@services-candidat/fiche-demande-aide.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { InvitationContactModalComponent } from '@shared-candidat/components/modals/invitation-contact-modal/invitation-contact-modal.component';
import { SelectDocumentModalComponent } from '@shared-candidat/components/modals/select-document-modal/select-document-modal.component';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  Contact,
  DemandeComplements,
  DocumentAapModel,
  DocumentConfigModel,
  DocumentHelper,
  DocumentProjet,
  DocumentService,
  EnumDocumentComplementaireType,
  EnumFeatureFlipping,
  EnumMotifNonRattachement,
  EnumProjetEtape,
  EnumQualifRue,
  EnumRoleContact,
  EnumRoleStructure,
  EnumScope,
  EnumStatutDemandeAide,
  EnumStatutSignataire,
  EnumTypeDocument,
  EnumTypePartenaire,
  EnumTypeStructure,
  EnumTypeVoie,
  FicheDemandeAidePresent,
  FILE_SIZE,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  InformationBancaire,
  InformationsBancairesHttpService,
  Invitation,
  MembreEquipe,
  Projet,
  ShowToastrService,
  SignalService,
  Signataire,
  Status,
  StatutGrilleImpacts,
  Structure,
  StructureValidationFunction,
  SubscriptionDestroyerComponent,
  UploadDocModalDocumentModel,
  UploadDocModalResultModel,
  Utilisateur,
} from '@shared-ui';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { concatMap, finalize, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-consortium-info',
  templateUrl: './projet-consortium-info.component.html',
  styleUrls: ['./projet-consortium-info.component.scss'],
})
export class ProjetConsortiumInfoComponent extends SubscriptionDestroyerComponent implements OnInit, OnDestroy {
  readonly RESPONSABLE_PROJET = EnumRoleContact[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  readonly REPRESENTANT_LEGAL = EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()];
  readonly RESPONSABLE_ADMINISTRATIF = EnumRoleContact[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()];
  readonly AUTRE = EnumRoleContact[EnumRoleContact.AUTRE.toString()];
  EnumStatutDemandeAide = EnumStatutDemandeAide;

  readonly CONNAISSANCE_CLIENT_FILE_TYPES = [
    'application/pdf', // .pdf
    'image/gif', // .gif
    'image/png', // .png
    'image/jpeg', // .jpg, .jpeg
    'image/bmp', // .bmp
    'image/tiff', // .tif, .tiff
    'application/vnd.ms-outlook', // .msg
  ];
  readonly DEFAULT_FILE_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
  ];

  readonly FILE_SIZE_LIMIT = FILE_SIZE;
  readonly FICHE_DMD_AIDE_FILE_EXTENSION = '.pdf';

  error = false;
  errorName: string;

  dossier: string;
  projet: Projet = new Projet();
  structure: Structure;
  structures: Structure[] = [];
  allStructures: Structure[] = [];
  utilisateur: Utilisateur;
  responsableProjet: Contact;
  representantLegaux: Contact[] = [];
  autresContacts: Contact[] = [];
  autresContactsDeleted: Contact[] = [];
  contacts: Contact[] = [];
  invitations: Invitation[] = [];
  membresEquipe: MembreEquipe[] = [];
  structureId: string;
  aap: Aap = new Aap();
  documentStructure: DocumentProjet = new DocumentProjet();
  etapeProjet: EnumProjetEtape;
  allDocuments: DocumentProjet[] = [];
  listDocumentStructure: DocumentProjet[] = [];
  ficheDmdAideDocuments: DocumentProjet[] = [];
  signataires: Signataire[] = [];
  signatairesValide: Signataire[] = [];
  signatairesInvalide: Signataire[] = [];

  subscriptions: Subscription[] = [];
  listDocAapStructure: DocumentConfigModel[] = [];
  listDocAapStructureActio: DocumentConfigModel[] = [];
  documentConfigsForComplement: DocumentConfigModel[] = [];
  demandesComplements: DemandeComplements[] = [];
  demandesComplementsEncours: DemandeComplements[] = [];
  documentsComplementaires: DocumentProjet[] = [];
  fileTooBig = false;
  fileNotSupported = false;
  fileActioTooBig = false;
  fileActioNotSupported = false;
  fileComplTooBig = false;
  fileComplNotSupported = false;
  connaissanceClientFileNotSupported = false;
  connaissanceClientFileActioNotSupported = false;
  connaissanceClientFileComplNotSupported = false;
  allowDownload = false;

  responsableProjetBoolean = false;
  responsableProjetEquipeBoolean = false;
  responsableAdministratifBoolean = false;

  structureType = EnumTypeStructure;
  public enumRaisonSiret = EnumMotifNonRattachement;

  isUploading = false;
  isKpiStructureNeeded = false;

  isStructureUpdatableByUser = false;
  isStatutCompleted = false;
  isGrilleImpactsAccessible = false;
  isFicheDemandeAccessible = false;
  isFicheDemandeAuthorized = false;
  demandeDeCorrectionExist = false;
  demandeDeCorrectionTreated = false;

  isFicheDemandeAidePresent = true;
  isSectionsRenseignees = false;
  isInfosGeneralesRenseignees = false;
  isStructureMandataire = false;
  ficheDemandeAideStatut: EnumStatutDemandeAide;
  ficheDemandeAideNeeded = true;

  selectedInformationBancaire?: InformationBancaire;
  nameFileNotSupported: string[] = [];
  nameFileActioNotSupported: string[] = [];

  isTypeStructureAndBudgetSaisi = false;
  structureNeedsToFillRibIban = false;
  fileDemandeAide: File;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public projetService: ProjetService,
    private structureService: StructureService,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    public userService: UserService,
    public uploadDocumentService: UploadDocumentService,
    public sharedFunction: SharedFunction,
    public structureValidationFunction: StructureValidationFunction,
    private grilleImpactHttpService: GrilleImpactHttpService,
    private demandeComplementService: DemandeDeDocumentComplementaireService,
    private ficheDemandeAideService: FicheDemandeAideService,
    public informationsBancairesHttpService: InformationsBancairesHttpService,
    public documentService: DocumentService,
    private signalService: SignalService
  ) {
    super();
    this.structureId = this.route.snapshot.params.structureId;
  }

  ngOnInit(): void {
    this.getProjetIfExist();
    this.loadInformationsBancaires();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.isUploading;
  }

  /*
   * Cette méthode récupère le projet si l'id est renseigné
   * */
  getProjetIfExist(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          mergeMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.etapeProjet = this.sharedFunction.getProjectEtapeName(this.projet);

              this.subscriptions.push(
                this.userService.getUserObservable().subscribe(response => {
                  if (response) {
                    this.utilisateur = response;
                  }
                })
              );

              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.isKpiStructureNeeded = this.aap.kpis?.filter(kpi => kpi.scope === 'STRUCTURE').length > 0;
              this.isFicheDemandeAidePresent = this.checkPresenceFicheDemandeAide(this.aap.ficheDemandeAidePresent, this.etapeProjet);
              this.dossier = this.aap.lienDocumentation;
              if (this.structureId != null) {
                this.getStructDocuments();
                this.getProjetStructures();
              }
              this.updateGrilleImpactsStatus();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  getProjetStructures(): void {
    this.subscriptions.push(
      this.structureService.getStructureById(this.structureId).subscribe(response => {
        if (response) {
          this.subscriptions.push(
            this.structureService.getStructuresListObservable().subscribe(structures => {
              this.allStructures = structures;
              this.structures = structures.filter(structure => !structure.closed);
            })
          );
          this.structureService.setUniqueStructureListObservable(response.body, this.allStructures);
          this.structure = response.body;
          this.signalService.setStructure(this.structure);

          this.structureNeedsToFillRibIban = this.structureValidationFunction.structureNeedsToFillRibIban(
            this.projet,
            this.aap,
            this.structure
          );

          this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
            this.aap,
            this.projet,
            this.structure,
            this.utilisateur
          );
          this.isGrilleImpactsAccessible = this.isFicheDemandeAccessible =
            this.sharedFunction.isStructureNotMandantaireAndHasUserInContacts(this.structure, this.utilisateur) ||
            this.sharedFunction.isUserMandataire(this.projet.id, this.utilisateur);

          this.isFicheDemandeAccessible &&= this.isStructureUpdatableByUser;

          this.listDocAapStructure = this.sharedFunction.getDocStructureAap(this.aap, this.structure, this.projet);
          this.listDocAapStructureActio = this.sharedFunction.getDocActioStructureAap(this.aap, this.structure, this.projet);

          this.listDocAapStructure.sort((a, b) => (a.nomDocument.toLowerCase() > b.nomDocument.toLowerCase() ? 1 : -1));
          this.listDocAapStructureActio.sort((a, b) => (a.nomDocument.toLowerCase() > b.nomDocument.toLowerCase() ? 1 : -1));

          this.formatSiretStructure();
          this.structureService
            .getInvitationsContactsStructure(this.structure.id)
            .subscribe(response => (this.invitations = response.body ?? []));
          this.responsableProjet = this.structure.contacts.filter(contact => contact.roles.includes(this.RESPONSABLE_PROJET))[0];
          this.representantLegaux = this.structure.contacts.filter(contact => contact.roles.includes(this.REPRESENTANT_LEGAL));
          this.contacts = this.structure.contacts.filter(contact => !contact.inactif);
          this.autresContacts = this.structure.contacts.filter(
            contact => !contact.roles.includes(this.REPRESENTANT_LEGAL) || contact.roles.length === 0
          );
          this.autresContactsDeleted = this.autresContacts.filter(contact => contact.inactif);
          this.autresContacts = this.autresContacts.filter(contact => !contact.inactif);
          this.membresEquipe = this.structure.equipe;
          this.responsableProjetBoolean = this.structureValidationFunction.checkExistanceResponsable(
            this.structure,
            EnumRoleContact.RESPONSABLE_PROJET
          );
          this.responsableAdministratifBoolean = this.structureValidationFunction.checkExistanceResponsable(
            this.structure,
            EnumRoleContact.RESPONSABLE_ADMINISTRATIF
          );
          this.responsableProjetEquipeBoolean = this.structureValidationFunction.checkExistanceResponsableProjetEquipe(this.structure);
          this.demandeDeCorrectionExist = this.sharedFunction.isDemandeRectificationExist(this.structure);
          this.demandeDeCorrectionTreated = this.sharedFunction.isDemandeRectificationTreated(this.structure);

          this.isSectionsRenseignees = this.checkSectionsRensigneesForDisplayFicheDemandeAide();
          this.isInfosGeneralesRenseignees = !this.checkInfosGeneralesNotRenseignees();
          this.isStructureMandataire = this.checkIsStructureMandataire();
          if (this.isFicheDemandeAidePresent) {
            this.getFicheDemandeAideStatut();
          }
          this.ficheDemandeAideNeeded = this.checkFicheDemandeAideNeeded();
          this.isTypeStructureAndBudgetSaisi = this.isTypeStructureAndBudgetComplete();
          this.loadSignataires();
        }
      })
    );
  }

  getStructDocuments(): void {
    const typesDocuments = [
      EnumTypeDocument.STRUCTURE,
      EnumTypeDocument.STRUCTURE_ACTIONARIAL,
      EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE,
      EnumTypeDocument.FICHE_DEMANDE_AIDE,
      EnumTypeDocument.DOCUMENT_NON_FOURNI,
    ];
    const fork = forkJoin({
      documents: this.documentService.getDocuments(this.projet.id, typesDocuments, this.structureId),
      demandesComplements: this.demandeComplementService.getDemandesComplements(this.projet.id, this.structureId),
    });
    this.subscriptions.push(
      fork.subscribe({
        next: resp => {
          this.demandesComplements = resp.demandesComplements.body;
          this.demandesComplementsEncours = this.demandesComplements.filter((d: DemandeComplements) =>
            ['ENVOYEE', 'COMPLETEE'].includes(d.statut)
          );

          this.allDocuments = resp.documents;
          this.handleDocuments();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  private handleDocuments() {
    this.listDocumentStructure = this.allDocuments.filter(
      (doc: DocumentProjet) =>
        doc.typeDoc !== EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE && doc.typeDoc !== EnumTypeDocument.FICHE_DEMANDE_AIDE
    );
    this.documentsComplementaires = this.allDocuments.filter(
      (doc: DocumentProjet) => doc.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE
    );
    this.ficheDmdAideDocuments = this.allDocuments.filter((doc: DocumentProjet) => doc.typeDoc === EnumTypeDocument.FICHE_DEMANDE_AIDE);
    this.documentsComplementaires.forEach(
      doc => (doc.type = EnumDocumentComplementaireType.toString(doc.type as EnumDocumentComplementaireType))
    );
    this.documentConfigsForComplement = this.getDocumentConfigsForComplements();
  }

  private getDocumentConfigsForComplements(): DocumentConfigModel[] {
    return this.demandesComplementsEncours
      .filter(demande => demande.documents && demande.documents.length > 0)
      .flatMap(demande =>
        demande.documents.map(document => ({
          nomDocument: document.type,
          etapes: [],
          typeStructures: [],
          scope: EnumScope.STRUCTURE,
          accesDocument: '',
          connaissanceClient: false,
          obligatoire: document.obligatoire,
          demandeComplementId: demande.id,
          demandeComplementDocumentId: document.id,
          description: document.description,
        }))
      );
  }

  loadInformationsBancaires(): void {
    this.informationsBancairesHttpService
      .getInformationsBancaires(this.structureId)
      .pipe(
        this.takeUntilDestroyed(),
        map(response => response.body ?? [])
      )
      .subscribe((informationsBancaires: InformationBancaire[]) => {
        this.selectedInformationBancaire = informationsBancaires.filter(info => info.selectionne)[0];
      });
  }

  private updateGrilleImpactsStatus() {
    if (!this.isKpiStructureNeeded) {
      this.isStatutCompleted = true;
      return;
    }
    const sub = this.projetService
      .getProjetObservable()
      .pipe(
        concatMap(projet => this.grilleImpactHttpService.getProjectAndStructuresKpisStatus(projet?.id)),
        map(grilleImpactsStatuts => GrilleImpactHelperService.getGIStructureStatut(grilleImpactsStatuts, this.structureId))
      )
      .subscribe(this.setGrilleImpactsStatus.bind(this));
    this.subscriptions.push(sub);
  }

  private setGrilleImpactsStatus(statut: StatutGrilleImpacts) {
    this.isStatutCompleted = GrilleImpactHelperService.isGrilleImpactsValid(statut);
  }

  uploadButtonIsActive(): boolean {
    return this.isStructureUpdatableByUser && this.needsDocuments();
  }

  checkIfUserNotInStructure(): boolean {
    const contactMatchUser = this.structure?.contacts.filter(
      contact => contact.matricule?.toUpperCase() === this.utilisateur.matricule?.toUpperCase()
    );
    return contactMatchUser.length === 0;
  }

  /*
   * Supprime la structure
   * */
  deleteStructure(): void {
    if (this.isStructureUpdatableByUser) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          description: `<p>Êtes-vous sûr de vouloir supprimer cette structure.</p>
                        <p>Tous les accès des contacts associés vont être révoqués.</p>
                            <p>Cette action est irréversible. </p>`,
          textGoButton: 'Oui',
          textReturnButton: 'Non',
          icon: true,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const nameStructureToDelete = this.structure.raisonSocial;
          this.structure.closed = true;
          this.subscriptions.push(
            this.structureService.deleteStructure(this.structureId, this.structure).subscribe({
              next: (response: any) => {
                if (response?.status === 206) {
                  // status 206 notification n'est pas envoyée
                  this.showToastrService.error("Une erreur s'est produite. Aucun contact dans la structure n'a été notifié.");
                } else {
                  this.showToastrService.success('La structure ' + nameStructureToDelete + ' a bien été supprimée');
                }
                this.onGoToConsortium();
              },
              error: (err: HttpErrorResponse) => {
                this.showToastrService.checkCodeError(err?.error);
              },
            })
          );
        }
      });
    }
  }

  /*
   *  Reformat le Siret affiché
   * */
  formatSiretStructure(): void {
    if (this.structure.siret) {
      this.structure.siret = this.structure.siret.replace(/.{13}(?=.)/, m => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& ')));
    }
    if (this.structure.lieuRD?.siret) {
      this.structure.lieuRD.siret = this.structure.lieuRD.siret.replace(/.{13}(?=.)/, m =>
        m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))
      );
    }
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortium(): void {
    this.router.navigate(['/projet-creation', this.route.snapshot.parent.params.id, 'projet-consortium']);
  }

  onGoToDonneesFinancieres(): void {
    this.router.navigate(['projet-consortium-donnees-financieres'], { relativeTo: this.route });
  }

  onGoToPrevisionsEconomiques(): void {
    this.router.navigate(['projet-consortium-previsions-economiques'], { relativeTo: this.route });
  }

  /*
   * Permet d'aller a la page modification contact
   * */
  onGoToContact(contact: Contact, role?: string): void {
    if (this.isStructureUpdatableByUser) {
      let contactId = '';
      if (role) {
        contactId = role;
      } else if (contact) {
        contactId = this.structure.contacts.indexOf(contact).toString();
      }

      this.router.navigate(['projet-consortium-contact/' + contactId], { relativeTo: this.route });
    }
  }

  onGoToProjetBudget(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-budget'], { relativeTo: this.route });
    }
  }

  onGoToRibIban(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-information-bancaire'], { relativeTo: this.route });
    }
  }

  onGoToGrilleImpacts(roleStructure: EnumRoleStructure): void {
    if (this.isGrilleImpactsAccessible && this.checkFinancement()) {
      this.router.navigate(['projet-consortium-grille-impacts'], {
        relativeTo: this.route,
        state: { role: roleStructure },
      });
    }
  }

  /*
   * Affiche la modale pour ajouter un document
   * */
  onUploadDocument(docs: UploadDocModalDocumentModel[]): void {
    if (this.isStructureUpdatableByUser && !this.disableDoc() && this.uploadButtonIsActive()) {
      this.handleUpload(docs, EnumScope.STRUCTURE);
    }
  }

  onUploadDocumentDnd(result: UploadDocModalResultModel, scope: EnumScope) {
    this.allowDownload = true;
    this.onUpload(result.files, scope, result.document, result.commentaire);
  }

  updateDocumentsAfterDelete(documentProjet: DocumentProjet) {
    this.allDocuments = this.allDocuments.filter(doc => doc.id !== documentProjet.id);
    this.handleDocuments();
  }

  onGoToFicheDemandeAide(): void {
    if (this.isFicheDemandeAuthorized) {
      this.router.navigate(['projet-consortium-fiche-demande-aide'], { relativeTo: this.route });
    } else {
      this.showToastrService.error(
        'Vous n’êtes pas habilité.e à ajouter, visualiser ou supprimer la fiche de demande d’aide, vous pouvez vous synchroniser avec les autres contacts de votre structure ou de la structure mandataire si présente pour pouvoir être habilité.e.'
      );
    }
  }

  handleUpload(docs: UploadDocModalDocumentModel[], scope: EnumScope): void {
    const dialogRef = this.dialog.open(SelectDocumentModalComponent, {
      data: {
        title: 'Ajouter un fichier',
        docList: docs,
        projet: this.projet,
        isAuthorized: this.isStructureUpdatableByUser,
        textGoButton: 'Ajouter',
        textReturnButton: 'Annuler',
        isProjetInfoPage: false,
        accept: '.pdf, .csv, .xls, .xlsx, .doc, .docx, .ppt, .pptx',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const files = result[1];
        const documentUploadModel = result[2];
        this.allowDownload = true;
        this.onUpload(files, scope, documentUploadModel);
      }
    });
  }

  onUploadFicheDemandeAide(event: any): void {
    this.error = false;

    const fileInput = event.target as HTMLInputElement;
    if (!this.validateFile(fileInput)) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadDocumentProjet(this.buildFicheDemandeAide(file), file);
  }

  private buildFicheDemandeAide(file: File) {
    const document = new DocumentProjet();
    document.nom = file.name;
    document.typeDoc = 'FICHE_DEMANDE_AIDE';
    document.scope = EnumScope.STRUCTURE;
    document.projetId = this.projet.id;
    document.structureId = this.structureId;
    document.dateModification = this.projet.dateModification;
    document.etape = this.sharedFunction.getProjectEtapeName(this.projet);
    document.type = "Fiche de demande d'aide";
    return document;
  }

  private validateFile(fileInput: HTMLInputElement): boolean {
    if (!fileInput?.files || fileInput.files.length === 0 || !fileInput.files[0]) {
      return false;
    }
    this.fileDemandeAide = fileInput.files[0];

    if (
      !this.fileDemandeAide.name.toLowerCase().endsWith(this.FICHE_DMD_AIDE_FILE_EXTENSION) ||
      !this.isFileSizeValid(this.fileDemandeAide)
    ) {
      this.nameFileNotSupported.push(this.fileDemandeAide.name);
      this.error = true;
      return false;
    }
    return true;
  }

  onDeleteFicheDmdAideDocument(document: DocumentProjet): void {
    this.ficheDmdAideDocuments = this.ficheDmdAideDocuments.filter(doc => doc.id !== document.id);
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  isFileSizeValid(file: File): boolean {
    return !(file?.size && file?.size > FILE_SIZE * 1048576);
  }

  isFileTypeValid(file: File, supportedTypesExtensions: string[]): boolean {
    return supportedTypesExtensions.includes(file.type);
  }

  addToNotSupportedFiles(file: File, scope: EnumScope): void {
    if (scope === EnumScope.STRUCTURE) {
      this.nameFileNotSupported.push(file.name);
      this.nameFileNotSupported = this.nameFileNotSupported.filter((item, index) => this.nameFileNotSupported.indexOf(item) === index);
    } else if (scope === EnumScope.STRUCTURE_ACTIONARIAL) {
      this.nameFileActioNotSupported.push(file.name);
      this.nameFileActioNotSupported = this.nameFileActioNotSupported.filter(
        (item, index) => this.nameFileActioNotSupported.indexOf(item) === index
      );
    }
  }

  setFileInvalidityFlags(
    document: UploadDocModalDocumentModel,
    scope: EnumScope,
    isFileSizeValid: boolean,
    isFileTypeValid: boolean
  ): void {
    const isConnaissanceClient = !!document.connaissanceClient;
    const isDocumentComplementaire = !!document.demandeComplementId;
    const isDocumentStructure = !isDocumentComplementaire && scope === EnumScope.STRUCTURE;
    const isDocumentStructureActionariale = !isDocumentComplementaire && scope === EnumScope.STRUCTURE_ACTIONARIAL;

    this.fileTooBig = isDocumentStructure && !isFileSizeValid;
    this.fileActioTooBig = isDocumentStructureActionariale && !isFileSizeValid;
    this.fileComplTooBig = isDocumentComplementaire && !isFileSizeValid;

    this.fileNotSupported = isDocumentStructure && !isConnaissanceClient && !isFileTypeValid;
    this.fileActioNotSupported = isDocumentStructureActionariale && !isConnaissanceClient && !isFileTypeValid;
    this.fileComplNotSupported = isDocumentComplementaire && !isConnaissanceClient && !isFileTypeValid;

    this.connaissanceClientFileNotSupported = isDocumentStructure && isConnaissanceClient && !isFileTypeValid;
    this.connaissanceClientFileActioNotSupported = isDocumentStructureActionariale && isConnaissanceClient && !isFileTypeValid;
    this.connaissanceClientFileComplNotSupported = isDocumentComplementaire && isConnaissanceClient && !isFileTypeValid;
  }

  resetFileInvalidityFlags(): void {
    this.fileTooBig = false;
    this.fileActioTooBig = false;
    this.fileComplTooBig = false;

    this.fileNotSupported = false;
    this.fileActioNotSupported = false;
    this.fileComplNotSupported = false;

    this.connaissanceClientFileNotSupported = false;
    this.connaissanceClientFileActioNotSupported = false;
    this.connaissanceClientFileComplNotSupported = false;
  }

  onUpload($event: any, scope: EnumScope, document?: UploadDocModalDocumentModel, commentaire?: string): void {
    if (commentaire) {
      const documentProjet = new DocumentProjet();
      documentProjet.type = document?.nom;
      documentProjet.typeDoc = EnumTypeDocument.DOCUMENT_NON_FOURNI;
      documentProjet.createur = this.utilisateur?.matricule;
      documentProjet.scope = scope;
      documentProjet.projetId = this.projet.id;
      documentProjet.structureId = this.structureId;
      documentProjet.commentaire = commentaire;
      this.uploadDocumentProjet(documentProjet, null);
      return;
    }

    this.resetFileInvalidityFlags();
    for (const item of Array.from($event)) {
      if (!this.allowDownload) continue;
      const isConnaissanceClient = !!document.connaissanceClient;
      const fileToUpload = item as File;
      const fileTypes = isConnaissanceClient ? this.CONNAISSANCE_CLIENT_FILE_TYPES : this.DEFAULT_FILE_TYPES;
      const isFileSizeValid = this.isFileSizeValid(fileToUpload);
      const isFileTypeValid = this.isFileTypeValid(fileToUpload, fileTypes);
      if (!isFileTypeValid || !isFileSizeValid) this.addToNotSupportedFiles(fileToUpload, scope);

      if (fileToUpload && isFileSizeValid && isFileTypeValid) {
        this.uploadFile(fileToUpload, scope, document);
      } else {
        this.setFileInvalidityFlags(document, scope, isFileSizeValid, isFileTypeValid);
      }
    }
    this.allowDownload = false;
  }

  private uploadFile(file: File, scope: EnumScope, document: UploadDocModalDocumentModel) {
    const documentProjet = this.buildDocumentProjet(file, scope, document);
    this.uploadDocumentProjet(documentProjet, file);
  }

  private buildDocumentProjet(file: File, scope: EnumScope, document: UploadDocModalDocumentModel) {
    const documentStructure = new DocumentProjet();
    documentStructure.nom = file.name;
    documentStructure.dateModification = this.projet.dateModification;
    documentStructure.projetId = this.projet.id;
    documentStructure.scope = scope;
    documentStructure.typeDoc = document.demandeComplementId ? 'DOCUMENT_COMPLEMENTAIRE' : scope;
    documentStructure.structureId = this.structureId;
    documentStructure.demandeComplementId = document.demandeComplementId;
    if (documentStructure.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE) {
      documentStructure.type = document?.nom ? (EnumDocumentComplementaireType.toKey(document?.nom) as string) : '';
    } else {
      documentStructure.type = document?.nom ?? '';
    }
    documentStructure.demandeComplementDocumentId = document.demandeComplementDocumentId;
    documentStructure.etape = this.sharedFunction.getProjectEtapeName(this.projet);
    documentStructure.codeGed = document && document.codeGed ? document.codeGed : null;
    documentStructure.domaineGed = document && document.domaineGed ? document.domaineGed : null;
    return documentStructure;
  }

  /*
   * Download a document
   * */
  canDownload(document: DocumentProjet): boolean {
    return this.utilisateur.matricule === document.createur;
  }

  /*
   * Affiche le role du contact selon l'enum
   * */
  displayedRoleContact(role: EnumRoleContact): string {
    if (role === this.RESPONSABLE_PROJET) {
      return 'Responsable projet';
    }
    if (role === this.RESPONSABLE_ADMINISTRATIF) {
      return 'Responsable administratif et financier';
    }
    return '';
  }

  /*
   * Affiche le numero de telephone au bon format
   * */
  parsePhoneNumber(phone: string): string {
    phone = phone?.replace(/\d{2}(?=.)/g, '$& ');
    return phone;
  }

  /*
   * Cette fonction retourne l'adresse du siége et le lieu de réalisation
   * */
  getAdresseSiege(SiegeLieuRD: string): string {
    let structureAdresse;
    if (SiegeLieuRD === 'siege') {
      structureAdresse = this.structure;
    } else {
      structureAdresse = this.structure.lieuRD;
    }
    if (structureAdresse.adresse?.codePays === 'FR') {
      const qualifRue = (EnumQualifRue as any)[structureAdresse.adresse?.complement] || structureAdresse.adresse?.complement;
      const typeVoie = (EnumTypeVoie as any)[structureAdresse.adresse?.typeVoie] || structureAdresse.adresse?.typeVoie;
      return (
        this.getInfoItem(structureAdresse.adresse?.numero, ' ') +
        ' ' +
        this.getInfoItem(qualifRue?.toLowerCase(), '') +
        ' - ' +
        this.getInfoItem(typeVoie?.toLowerCase(), ' ') +
        ' ' +
        this.getInfoItem(structureAdresse.adresse?.voie, ' ') +
        ' - ' +
        this.getInfoItem(structureAdresse.adresse?.cp, ' ') +
        ' - ' +
        this.getInfoItem(structureAdresse.adresse?.ville, ' ') +
        ' - ' +
        this.getInfoItem(structureAdresse.adresse?.pays, ' ')
      );
    } else {
      const voie = structureAdresse.adresse?.voie != null ? `${structureAdresse.adresse.voie} - ` : '';
      const cp = structureAdresse.adresse.cp != null ? `${structureAdresse.adresse.cp} ` : '';
      const ville = structureAdresse.adresse.ville != null ? `- ${structureAdresse.adresse.ville}` : '';
      const pays = structureAdresse.adresse?.pays != null ? ` - ${structureAdresse.adresse.pays} ` : '';

      return voie + cp + ville + pays;
    }
  }

  getInfoItem(infoItem: any, defaultValue: string) {
    return infoItem || defaultValue;
  }

  /*
   * Cette fonction retourne la valeur de l'enum typeStructure
   * */
  getTypeStructure(typeStructure: string): string {
    return EnumTypeStructure[typeStructure] ? EnumTypeStructure.toString(typeStructure as EnumTypeStructure) : '';
  }

  /*
   * Popup invitation contact
   * */
  inviteContact(): void {
    const dialogRef = this.dialog.open(InvitationContactModalComponent, {
      data: {
        title: 'Inviter un contact',
        isAuthorized: this.isStructureUpdatableByUser,
        aap: this.aap,
        textGoButton: 'Inviter',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(emailResult => {
      if (emailResult) {
        const contact = new Contact();
        contact.email = emailResult;
        contact.roles = [this.AUTRE];
        contact.dateModification = this.structure.dateModification;

        const existingContact = this.autresContactsDeleted.find(c => c.email === emailResult);
        if (existingContact) {
          this.showToastrService.error(
            'Un contact avec ce mail existe déjà au niveau de la structure dans la section "Contact(s) supprimé(s)" vous pouvez le réinviter en cliquant sur le contact'
          );
        } else {
          this.subscriptions.push(
            this.structureService.addContactStructure(this.structureId, contact).subscribe({
              next: resp => {
                if (resp) {
                  // status 206 notification n'est pas envoyée
                  if (resp.status === 206) {
                    this.showToastrService.error(
                      "Une erreur s'est produite. L'invitation n'a pas été envoyée. Veuillez renvoyer l'invitation depuis la fiche contact dans la structure."
                    );
                  } else {
                    this.showToastrService.success('Le contact a bien été invité');
                  }
                  this.autresContacts.push(contact);
                  this.structureService.setUniqueStructureListObservable(resp.body, this.allStructures);

                  this.contacts.push(contact);
                  this.projet.dateModification = resp.body.dateModification;
                  this.getProjetIfExist();
                }
              },
              error: (err: HttpErrorResponse) => {
                this.showToastrService.error(err?.error?.message);
              },
            })
          );
        }
      }
    });
  }

  getInvitation(contact: Contact): Invitation | undefined {
    return this.invitations.find(invitation => invitation.emailUtilisateur === contact.email);
  }

  statutInvitation(contact: Contact): string {
    const invitation = this.getInvitation(contact);
    if (invitation?.expired) {
      return 'Invitation Expirée';
    }
    return 'Invitation En attente';
  }

  /*
   * Permet de desactiver l'upload de document
   * */
  disableDoc(): boolean {
    let budget;
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      budget = this.structure.budgetPreDepot;
    } else {
      budget = this.structure.budgetDepot;
    }
    return (
      ((!budget?.montant || !this.structure.typeStructure || !budget.besoin) && !this.checkIsStructureMandataire()) ||
      (!this.structure.typeStructure && this.checkIsStructureMandataire())
    );
  }

  /*
   * Permet d'aller a la page modification d'structure
   * */
  modifyStructure(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-creation', this.structureId], { relativeTo: this.route.parent });
    }
  }

  modifyStructureRD(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-lieu-rd', this.structureId], { relativeTo: this.route.parent });
    }
  }

  absenceSiret(lieuRD?: boolean): string {
    let structureLieuRD;
    if (lieuRD) {
      structureLieuRD = this.structure.lieuRD;
    } else {
      structureLieuRD = this.structure;
    }
    switch (structureLieuRD.raisonSiret) {
      case EnumMotifNonRattachement.IMMATRICULATION_EN_COURS:
        return "Structure en cours d'immatriculation au RCS";
      case EnumMotifNonRattachement.NON_IMMATRICULE:
        return 'Structure non immatriculée au RCS';
      case EnumMotifNonRattachement.EN_COURS_DE_CREATION:
        return 'Structure en cours de création';
    }
  }

  checkFinancement(): boolean {
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      if (this.structure.budgetPreDepot) {
        return this.structure.budgetPreDepot.besoin;
      }
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      if (this.structure.budgetDepot) {
        return this.structure.budgetDepot.besoin;
      }
    }
    return true;
  }

  getBudget(): string {
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      return this.structure.budgetPreDepot.montant == undefined ? '0' : String(this.structure.budgetPreDepot.montant);
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      return this.structure.budgetDepot.montant == undefined ? '0' : String(this.structure.budgetDepot.montant);
    }
    return '-';
  }

  absenceSiretRD(): string {
    if (this.structure.lieuRD.raisonSiret === EnumMotifNonRattachement.IMMATRICULATION_EN_COURS) {
      return "Structure en cours d'immatriculation au RCS";
    } else {
      return 'Structure non immatriculée au RCS';
    }
  }

  isTypeStructureAndBudgetComplete(): boolean {
    return this.structure.typeStructure != null && this.structureValidationFunction.isBudgetSet(this.aap, this.projet, this.structure);
  }

  structureBudgetComplete(): boolean {
    return (
      this.checkIsStructureMandataire() ||
      (this.structure.budgetDepot.montant !== null &&
        (this.etapeProjet !== EnumProjetEtape.PRE_DEPOT || (this.etapeProjet === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime)))
    );
  }

  /**
   * boolean qui indique si la structure doit uploader des documents
   */
  needsDocuments(): boolean {
    return (this.checkIsStructureMandataire() || this.structure.budgetDepot?.besoin) && this.listDocAapStructure.length !== 0;
  }

  /**
   * Retourne un Boolean pour afficher le lien de dossier de candidature
   */
  showDossierCandidatureLink(): boolean {
    return this.etapeProjet === EnumProjetEtape.PRE_DEPOT && this.aap.lienDocumentation?.length > 0;
  }

  uploadDocButtonIsValid(scope: EnumScope): boolean {
    let numberOfDocumentsUploadedInEtape;
    if (scope === EnumScope.STRUCTURE) {
      numberOfDocumentsUploadedInEtape = this.listDocumentStructure.filter(
        document =>
          (document.etape === this.sharedFunction.getProjectEtapeName(this.projet).toString() ||
            this.listDocAapStructure.filter(doc => doc.nomDocument === document.type).length > 0) &&
          document.scope === scope
      ).length;
    } else if (scope === EnumScope.STRUCTURE_ACTIONARIAL) {
      numberOfDocumentsUploadedInEtape = this.listDocumentStructure.filter(
        document =>
          (document.etape === this.sharedFunction.getProjectEtapeName(this.projet).toString() ||
            this.listDocAapStructureActio.filter(doc => doc.nomDocument === document.type).length > 0) &&
          document.scope === scope
      ).length;
    }

    return numberOfDocumentsUploadedInEtape > 0;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentProjet): boolean {
    return this.structureValidationFunction.isValidDocument(document);
  }

  isScanedDocument(document: DocumentProjet): boolean {
    return this.structureValidationFunction.isScanedDocument(document);
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  toUploadDocModalDocumentModel(documents: DocumentAapModel[]): UploadDocModalDocumentModel[] {
    return documents.map((document: DocumentAapModel) => ({
      nom: document.nomDocument,
      mediaTypes: document.mediaTypes,
      connaissanceClient: document.connaissanceClient,
    }));
  }

  checkPresenceFicheDemandeAide(fiche: FicheDemandeAidePresent, etapeProjet: EnumProjetEtape): boolean {
    return !(
      fiche === null ||
      !fiche?.active ||
      (fiche?.active && etapeProjet === EnumProjetEtape.PRE_DEPOT && fiche?.etape !== EnumProjetEtape.PRE_DEPOT)
    );
  }

  checkSectionsRensigneesForDisplayFicheDemandeAide(): boolean {
    return (
      this.structureValidationFunction.isBudgetSet(this.aap, this.projet, this.structure) &&
      this.structureValidationFunction.checkRepresentantLegal(this.structure) &&
      this.structureValidationFunction.checkInfosSiegeInfosStructure(this.structure) &&
      this.structureValidationFunction.checkLieuDeRealisation(this.structure)
    );
  }

  checkInfosGeneralesNotRenseignees(): boolean {
    return this.projet.nom == null && this.projet.acronyme == null && this.projet.description == null;
  }

  checkIsStructureMandataire(): boolean {
    return this.structure.role === EnumRoleStructure.MANDATAIRE;
  }

  getFicheDemandeAideStatut(): void {
    this.ficheDemandeAideService
      .getStatutFicheDemandeAide(this.structure.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: resp => {
          this.ficheDemandeAideStatut = resp.body;
          this.isFicheDemandeAuthorized = true;
        },
        error: (err: HttpErrorResponse) => {
          this.ficheDemandeAideStatut = null;
          if (err?.status === 404) {
            this.isFicheDemandeAuthorized = true;
          } else if (err?.status === 403) {
            this.isFicheDemandeAuthorized = false;
          } else {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
      });
  }

  private uploadDocumentProjet(documentProjet: DocumentProjet, file: File | undefined): void {
    this.isUploading = true;
    this.documentService
      .uploadDocument(documentProjet, file)
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => (this.isUploading = false))
      )
      .subscribe({
        next: (documentCreated: DocumentProjet) => {
          this.allDocuments = this.allDocuments.filter(
            doc => !(doc.type === documentCreated.type && doc.typeDoc === EnumTypeDocument.DOCUMENT_NON_FOURNI)
          );
          this.allDocuments.push(documentCreated);
          this.handleDocuments();
          if (!file) {
            this.showToastrService.success('Commentaire sauvegardé avec succès');
          } else {
            this.showToastrService.success('Le document a bien été ajouté');
          }
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  private checkFicheDemandeAideNeeded(): boolean {
    if (!this.aap?.ficheDemandeAidePresent?.etape) {
      return false;
    }

    if (
      this.aap?.ficheDemandeAidePresent?.etape === EnumProjetEtape.DEPOT &&
      this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT
    ) {
      return false;
    }

    const result =
      this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT
        ? this.structure?.budgetPreDepot?.besoin
        : this.structure?.budgetDepot?.besoin;

    return result ?? true;
  }

  onGoToSignataire(signataire: Signataire): void {
    if (this.isStructureUpdatableByUser) {
      const routeUrl = signataire?.id ? 'projet-consortium-signataire/' + signataire.id : 'projet-consortium-signataire';
      this.router.navigate([routeUrl], { relativeTo: this.route });
    }
  }

  private loadSignataires() {
    this.structureService.getSignataires(this.structureId).subscribe({
      next: response => {
        if (response.body) {
          this.signataires = response.body;
          this.signatairesValide = this.signataires.filter(
            signataire => signataire.statut !== EnumStatutSignataire.INVALIDE || signataire.isFromFicheDemandeAide
          );
          this.signatairesInvalide = this.signataires.filter(
            signataire => signataire.statut === EnumStatutSignataire.INVALIDE && !signataire.isFromFicheDemandeAide
          );
        }
      },
      error: err => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  disabledFicheDemandeAide(): boolean {
    return (
      ![EnumStatutDemandeAide.GENERATED, EnumStatutDemandeAide.COMPLETED].includes(this.ficheDemandeAideStatut) ||
      !this.ficheDemandeAideNeeded
    );
  }

  protected readonly EnumScope = EnumScope;
  protected readonly EnumRoleStructure = EnumRoleStructure;
  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
  protected readonly Status = Status;
}
