export enum EnumFeatureFlipping {
  VERROUILLAGE = 'verrouillage',
  ELIGIBILITE = 'eligibilite',
  AUDITION = 'audition',
  CONTRACTUALISATION = 'contractualisation',
  OPPORTUNITE = 'opportunite',
  AUTO_AFFECTATION = 'auto-affectation',
  CRITERE_EVAL_INDICATEUR_ELIGIBILITE = 'critere-eval-indicateur-eligibilite',
  STRATEGIE_ACCELERATION = 'strategie-acceleration', //DISPOSITIFS
  REFERENTIELS = 'referentiels',
  COMITES = 'comites',
  ORGANISMES = 'organismes',
  INDICATEURS_ELIGIBILITE = 'indicateurs-eligibilite',
  CRITERES_EVALUATION = 'criteres-evaluation',
  DATE_PERIODICITE = 'date-periodicite',
  PHASES = 'phases',
  STRUCTURE_PROJET = 'structure-projet',
  INFORMATION_GENERALE = 'information-generale',
  GRILLE_IMPACTS = 'grille-impacts',
  CATEGORIES = 'categories',
  POLES = 'poles',
  ROLE_CREATION_DC = 'role-creation-dc',
  CREATION_DC = 'creation-dossier-commercial',
  DEMANDE_DOCUMENTS_COMPLEMENTAIRES = 'demande-documents-complementaires',
  QLIK = 'qlik',
  INSTRUCTION_APPROFONDIE = 'instruction-approfondie',
  AAP_CONFIGURATION_DOCUMENT = 'aap-configuration-documents',
  REORIENTATION_V2 = 'reorientation-v2',
  DUPLICATION_V2 = 'duplication-v2',
  INTERVENTION_JPI = 'intervention-jpi',
  PARCOURS_SIGNATAIRES = 'parcours-signataires',
  PROCEDURES_SIGNATURE_ELECTRONIQUE = 'procedures-signature-electronique',
  RIB_IBAN = 'rib-iban',
  ANALYSE_COMPLETUDE_2EME_NIVEAU = 'analyse-completude-2eme-niveau',
  CONTROLES_DE_2ND_NIVEAU = 'controles-de-2nd-niveau',
  VALIDATION_GRILLE_D_IMPACTS = 'validation-grille-d-impacts',
  SELECTION = 'selection',
  SUIVI_DES_PROJETS = 'suivi-des-projets',
  // TODO : A revoir au clean de l'evaluateur
  INSTRUCTION_APPROFONDIE_EVALUATEUR_ILAB = 'instruction-approfondie-evaluateur-ilab',
  RECHERCHE_TRANSVERSE = 'recherche-transverse',
  NOTIFICATION = 'notification',
  STRUCTURE_PE_AF = 'structure-pe-af',
  CREATION_DOSSIER_COMMERCIAL_V2 = 'creation-dossier-commercial-v2',
}
