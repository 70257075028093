<div class="card--info card--blue card--form">
  <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
  Présentation de la répartition du capital social du demandeur représentatif de sa répartition au moment du dépôt de la demande d'aide
</div>

<form class="form-container" [formGroup]="ficheDemandeAideActionnairesForm">
  <h6 class="fw-bold">Déclaration des actionnaires<lib-asterisk></lib-asterisk></h6>
  <div class="">Actionnaires personnes physiques (insérez autant de lignes que nécessaire)</div>
  <mat-table
    formArrayName="actionnairesPhysique"
    [dataSource]="dataSourceActionnairesPhysique"
    class="table-responsive mat-elevation-z0"
    aria-describedby="tableau des actionnaires physiques"
  >
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterTogglePhysique() : null"
          [checked]="selectionPhysique.hasValue() && isAllSelectedPhysique()"
          [indeterminate]="selectionPhysique.hasValue() && !isAllSelectedPhysique()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell id="id" *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
        <mat-checkbox (change)="$event ? selectionPhysique.toggle(i) : null" [checked]="selectionPhysique.isSelected(i)"> </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="prenom">
      <mat-header-cell *matHeaderCellDef>Prénom</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            actionnairesPhysiqueFormArray.at(i)?.get('prenom')?.invalid && actionnairesPhysiqueFormArray.at(i)?.get('prenom')?.touched
          "
        >
          <span class="ds-input-group__input">
            <input
              id="prenom"
              name="prenom"
              maxlength="30"
              placeholder="Prénom"
              type="text"
              formControlName="prenom"
              class="ds-input-group__input"
            />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nom">
      <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            actionnairesPhysiqueFormArray.at(i)?.get('nom')?.invalid && actionnairesPhysiqueFormArray.at(i)?.get('nom')?.touched
          "
        >
          <span class="ds-input-group__input">
            <input id="nom" name="nom" maxlength="30" placeholder="Nom" type="text" formControlName="nom" class="ds-input-group__input" />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pays">
      <mat-header-cell *matHeaderCellDef>Pays de nationalité</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            actionnairesPhysiqueFormArray.at(i)?.get('pays')?.invalid && actionnairesPhysiqueFormArray.at(i)?.get('pays')?.touched
          "
        >
          <span class="ds-input-group__input">
            <input
              id="pays"
              name="pays"
              placeholder="Pays de nationalité"
              type="text"
              formControlName="pays"
              class="ds-input-group__input"
            />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pourcentageDetention">
      <mat-header-cell *matHeaderCellDef>Pourcentage de détention</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            (actionnairesPhysiqueFormArray.at(i)?.get('pourcentageDetention')?.invalid &&
              actionnairesPhysiqueFormArray.at(i)?.get('pourcentageDetention')?.touched) ||
            totalPourcentageDetention !== TOTAL_PERCENT
          "
        >
          <span class="ds-input-group__input">
            <input
              libDecimalNumber
              [fractionDigits]="DIGITS_NUMBER"
              id="pourcentageDetention"
              name="pourcentageDetention"
              placeholder="Pourcentage"
              maxlength="5"
              type="text"
              (keypress)="onkeypress($event)"
              (change)="checkValidators($event, 'physique', i)"
              formControlName="pourcentageDetention"
              class="ds-input-group__input"
            />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsActionnairesPhysique"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsActionnairesPhysique"></mat-row>
  </mat-table>
  <ng-container *ngIf="actionnairesPhysiqueFormArray.touched && actionnairesPhysiqueFormArray.invalid">
    <div id="error-aide-obtenues" class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
  </ng-container>
  <div class="button-container" *ngIf="!ficheDemandeAideActionnairesForm.disabled">
    <a (click)="removeActionnairePhysique()">
      <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
      <span class="action-btn">Supprimer ligne(s)</span>
    </a>
    <a (click)="addActionnairePhysique()">
      <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
      <span class="action-btn">Ajouter une ligne</span>
    </a>
  </div>

  <div class="">Actionnaires personnes morales (insérez autant de lignes que nécessaire)</div>
  <mat-table
    formArrayName="actionnairesMoral"
    [dataSource]="dataSourceActionnairesMoral"
    class="table-responsive mat-elevation-z0"
    aria-describedby="tableau des actionnaires moraux"
  >
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggleMoral() : null"
          [checked]="selectionMoral.hasValue() && isAllSelectedMoral()"
          [indeterminate]="selectionMoral.hasValue() && !isAllSelectedMoral()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell id="id" *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
        <mat-checkbox (change)="$event ? selectionMoral.toggle(i) : null" [checked]="selectionMoral.isSelected(i)"> </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="raisonSociale">
      <mat-header-cell *matHeaderCellDef>Raison sociale</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            actionnairesMoralFormArray.at(i)?.get('raisonSociale')?.invalid &&
            actionnairesMoralFormArray.at(i)?.get('raisonSociale')?.touched
          "
        >
          <span class="ds-input-group__input">
            <input
              id="raisonSociale"
              name="raisonSociale"
              maxlength="100"
              placeholder="Raison sociale"
              type="text"
              formControlName="raisonSociale"
              class="ds-input-group__input"
            />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="siren">
      <mat-header-cell *matHeaderCellDef>SIREN (ou équivalent)</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            actionnairesMoralFormArray.at(i)?.get('siren')?.invalid && actionnairesMoralFormArray.at(i)?.get('siren')?.touched
          "
        >
          <span class="ds-input-group__input">
            <input
              id="siren"
              name="siren"
              maxlength="25"
              placeholder="Siren ou équivalent"
              type="text"
              formControlName="siren"
              class="ds-input-group__input"
            />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pays">
      <mat-header-cell *matHeaderCellDef>Pays siège social</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            actionnairesMoralFormArray.at(i)?.get('pays')?.invalid && actionnairesMoralFormArray.at(i)?.get('pays')?.touched
          "
        >
          <span class="ds-input-group__input">
            <input id="pays" name="pays" placeholder="Pays siège social" type="text" formControlName="pays" class="ds-input-group__input" />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pourcentageDetention">
      <mat-header-cell *matHeaderCellDef>Pourcentage de détention</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            (actionnairesMoralFormArray.at(i)?.get('pourcentageDetention')?.invalid &&
              actionnairesMoralFormArray.at(i)?.get('pourcentageDetention')?.touched) ||
            totalPourcentageDetention !== TOTAL_PERCENT
          "
        >
          <span class="ds-input-group__input">
            <input
              libDecimalNumber
              [fractionDigits]="DIGITS_NUMBER"
              id="pourcentageDetention"
              name="pourcentageDetention"
              placeholder="Pourcentage"
              maxlength="5"
              type="text"
              (keypress)="onkeypress($event)"
              (change)="checkValidators($event, 'moral', i)"
              formControlName="pourcentageDetention"
              class="ds-input-group__input"
            />
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsActionnairesMoral"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsActionnairesMoral"></mat-row>
  </mat-table>
  <ng-container *ngIf="actionnairesMoralFormArray.touched && actionnairesMoralFormArray.invalid">
    <div id="error-aide-obtenues" class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
  </ng-container>

  <div class="button-container" *ngIf="!ficheDemandeAideActionnairesForm.disabled">
    <a (click)="removeActionnaireMoral()">
      <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
      <span class="action-btn">Supprimer ligne(s)</span>
    </a>
    <a (click)="addActionnaireMoral()">
      <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
      <span class="action-btn">Ajouter une ligne</span>
    </a>
  </div>

  <mat-table class="table-responsive mat-elevation-z0 pourcentageTable" [dataSource]="dataSourcePourcentageCalcul" aria-describedby="test">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>
    <ng-container matColumnDef="prenom">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>
    <ng-container matColumnDef="nom">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>
    <ng-container matColumnDef="pays">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>
    <ng-container matColumnDef="pourcentageDetention">
      <mat-header-cell [class.error]="TOTAL_PERCENT !== totalPourcentageDetention" *matHeaderCellDef>
        {{ calculPourcentageDetention() | number : '1.2-2' }}%
      </mat-header-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumnsActionnairesPhysique"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsActionnairesPhysique"></mat-row>
  </mat-table>

  <div *ngIf="totalPourcentageDetention !== TOTAL_PERCENT" class="error-pourcentage">
    La somme des pourcentages de détention des actionnaires ne peut être différente de 100%
  </div>
</form>
