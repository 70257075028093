import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContractualisationService } from '@services-candidat/contractualisation.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import {
  DocumentService,
  EnumProcedureSignatureStatut,
  EnumStatutSignature,
  EnumTypeDocument,
  EnumTypeSignataire,
  Environment,
  ProcedureSignatureStructure,
  Projet,
  ShowToastrService,
  SignataireProcedure,
  StructureSyntheseHelperService,
  SyntheseContractualisationProjet,
  SyntheseContractualisationStructure,
  TextModalComponent,
  Utilisateur,
} from '@shared-ui';
import { catchError, combineLatest, EMPTY, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-signature-contrat-consortium',
  templateUrl: './signature-contrat-consortium.component.html',
  styleUrls: ['./signature-contrat-consortium.component.scss'],
})
export class SignatureContratConsortiumComponent implements OnInit {
  syntheseContractualisationProjet: SyntheseContractualisationProjet;
  projet: Projet;
  projetId!: string;
  structureId!: string;
  private destroyRef = inject(DestroyRef);
  structureName: string;
  dateEcheance: Date;
  utilisateur!: Utilisateur;
  synthesesStructures: SyntheseContractualisationStructure[] = [];
  syntheseStructure!: SyntheseContractualisationStructure | undefined;
  currentProcedure!: ProcedureSignatureStructure | null;
  currentSigner!: SignataireProcedure | undefined;
  youSignUiId!: string;
  proceduresHistory!: ProcedureSignatureStructure[];
  showSignedDocuments = false;

  constructor(
    private projetService: ProjetService,
    private route: ActivatedRoute,
    private utilisateurService: UserService,
    public structureService: StructureService,
    public contractualisationService: ContractualisationService,
    private structureSyntheseHelperService: StructureSyntheseHelperService,
    public showToastrService: ShowToastrService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    @Inject('environment') private environment: Environment
  ) {}

  protected readonly EnumProcedureSignatureStatut = EnumProcedureSignatureStatut;

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent?.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    this.youSignUiId = this.environment.youSignUiId;

    combineLatest([this.loadUtilisateur(), this.loadProjet(), this.loadSynthesesStructures()]).subscribe(() => {
      this.syntheseStructure = this.synthesesStructures.find(synthese => synthese.id === this.structureId);
      this.currentProcedure = this.structureSyntheseHelperService.getCurrentProcedure(this.syntheseStructure);
      if (this.currentProcedure) {
        this.currentSigner = this.structureSyntheseHelperService.getCurrentSigner(this.currentProcedure, this.utilisateur, [
          EnumTypeSignataire.SIGNATAIRE_EXTERNE,
          EnumTypeSignataire.SOLIDAIRE_EXTERNE,
          EnumTypeSignataire.SUIVEUR_EXTERNE,
        ]);
        this.proceduresHistory = this.structureSyntheseHelperService.getProceduresHistory(this.syntheseStructure, this.utilisateur, [
          EnumTypeSignataire.SIGNATAIRE_EXTERNE,
          EnumTypeSignataire.SOLIDAIRE_EXTERNE,
        ]);
        this.showSignedDocuments = new Date(this.currentProcedure.dateFin) < new Date(Date.now() - 24 * 60 * 60 * 1000);
        if (this.showSignedDocuments) {
          this.loadDocuments();
        }
      }
    });

    this.contractualisationService
      .getSynthese(this.route.snapshot.parent?.params.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: syntheseContractualisationProjet => {
          this.syntheseContractualisationProjet = syntheseContractualisationProjet.body;
          this.dateEcheance = this.syntheseContractualisationProjet?.contractualisation?.procedureSignature.echeance?.dateEcheance;
        },
      });
  }

  loadDocuments(): void {
    this.documentService
      .getSignedDocuments(this.structureId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: documents => {
          this.syntheseStructure.contratDocument = documents.filter(document => document.typeDoc === EnumTypeDocument.CONTRAT)[0];
          this.syntheseStructure.mandatDocuments = documents.filter(document => document.typeDoc === EnumTypeDocument.MANDAT);
        },
      });
  }

  loadUtilisateur(): Observable<Utilisateur> {
    return this.utilisateurService.getUserObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(err => this.handleError(err)),
      tap(utilisateur => {
        this.utilisateur = utilisateur;
      })
    );
  }

  loadProjet(): Observable<Projet> {
    return this.projetService.getProjetObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(err => this.handleError(err)),
      tap(projet => {
        this.projet = projet;
      })
    );
  }

  loadSynthesesStructures(): Observable<SyntheseContractualisationStructure[]> {
    return this.structureService.getSyntheseContractualisationStructures(this.projetId).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response || []),
      catchError(err => this.handleError(err)),
      tap(syntheses => {
        this.synthesesStructures = syntheses;
      })
    );
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  openModalCommentaire(commentaire: string) {
    this.dialog.open(TextModalComponent, {
      data: {
        title: 'Commentaire',
        description: commentaire,
      },
    });
  }

  protected readonly EnumStatutSignature = EnumStatutSignature;
  protected readonly EnumTypeSignataire = EnumTypeSignataire;
}
