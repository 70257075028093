import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetInfoService } from '@features-candidat/projet-creation/service/projet-info.service';
import { AapService } from '@services-candidat/aap.service';
import { ContractualisationService } from '@services-candidat/contractualisation.service';
import { DepensesService } from '@services-candidat/depenses.service';
import { FicheDemandeAideService } from '@services-candidat/fiche-demande-aide.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { NpsModalComponent } from '@shared-candidat/components/modals/nps-modal/nps-modal.component';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  DemandeComplement,
  DemandeCorrection,
  DocumentProjet,
  DocumentProjetInterface,
  EnumAapStatut,
  EnumFeatureFlipping,
  EnumPermissionUtilisateur,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumRoleStructure,
  EnumScanDocument,
  EnumScope,
  EnumStatutDemandeAide,
  EnumTypeDocument,
  EnumTypePartenaire,
  EnumTypeSignataire,
  EnumTypeWorkflow,
  EnumValidation,
  FeatureFlagService,
  FicheComHelperService,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  GrilleImpactStatutModel,
  InformationsBancairesHttpService,
  PermissionUtils,
  Projet,
  ProjetEtape,
  ProjetValidationFunction,
  ShowToastrService,
  Structure,
  StructureSyntheseHelperService,
  StructureValidationFunction,
  Utilisateur,
  UtilisateurUtils,
} from '@shared-ui';
import { FicheDemandeAideStatut } from 'libs/shared-ui/src/lib/shared/models/fiche-demande-aide-statut.model';
import { Subscription } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-projet-apercu',
  templateUrl: './projet-apercu.component.html',
  styleUrls: ['./projet-apercu.component.scss'],
})
export class ProjetApercuComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  projet: Projet = new Projet();
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];
  infoValid: boolean;
  budgetValid: boolean;
  previsionEconomiqueValid = false;
  donneesFinancieresValid = false;
  docValid = true;
  structureValid: boolean;
  structureDocValid: boolean;
  structureDocMandataireValid: boolean;
  isGrillesImpactsStructuresValid: boolean;
  isGrilleImpactsProjetValid: boolean;
  isFicheDmdAideValid = false;
  isExpensesProjetValid = false;
  isFicheComValid = false;
  listDocumentProjet: DocumentProjet[] = [];
  isMandataireExist: boolean;
  mandataireValid: boolean;
  poleValid: boolean;
  secteursValid: boolean;
  domainesValid: boolean;
  thematiqueValid: boolean;
  enqueteValid: boolean;
  isKpiProjetNeeded = false;
  aapClosed = false;
  isStructureInformationBancaireValid = true;
  isSignatairesValid = true;

  currentUser: string;
  etapeActuelle: ProjetEtape = { nom: null, statut: null, date: null };
  structures: Structure[] = [];

  nbStructuresToCheck: number;
  demandesCorrection: DemandeCorrection[] = [];
  demandesDocComplement: DemandeComplement[] = [];

  projetId: string;
  canWriteModification: boolean;
  canWriteDemandeComplement = false;
  user: Utilisateur;

  private isKpiStructureNeeded = false;
  structureMandataireId: string;

  canUserSeeContract = false;
  isUserSuiveurExterne = false;

  allApproved = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private structureService: StructureService,
    public projetService: ProjetService,
    private depensesService: DepensesService,
    private projetInfoService: ProjetInfoService,
    private aapService: AapService,
    private userService: UserService,
    private showToastrService: ShowToastrService,
    public datePipe: DatePipe,
    public sharedFunction: SharedFunction,
    private grilleImpactHttpService: GrilleImpactHttpService,
    private ficheDemandeAideService: FicheDemandeAideService,
    public projetValidationFunction: ProjetValidationFunction,
    private informationsBancairesHttpService: InformationsBancairesHttpService,
    private featureFlagService: FeatureFlagService,
    public structureValidation: StructureValidationFunction,
    private contractualisationService: ContractualisationService,
    public helperService: StructureSyntheseHelperService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getProjet();
  }

  verifyAllSuiveurAprrovedDocs(): void {
    this.structureService.loadSyntheseContractualisationStructuresSubject().subscribe(synthesesStructures => {
      this.allApproved = synthesesStructures?.every(synthese => {
        const currentProcedure = this.helperService.getCurrentProcedure(synthese);
        return currentProcedure.signataires
          .filter(signataire => signataire.type === EnumTypeSignataire.SUIVEUR_EXTERNE)
          .every(signataire => signataire.approbation !== null && signataire.approbation !== undefined);
      });
    });
  }

  getUser(): void {
    this.userService.getUserObservable().subscribe((resp: Utilisateur) => {
      this.user = resp;
    });
  }

  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.projetService
      .getProjetById(this.projetId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        concatMap(responseProjet => {
          if (responseProjet) {
            this.projet = responseProjet.body;
            this.etapeActuelle = this.getEtapeProjet();
            return this.aapService.loadAapSubject();
          }
        })
      )
      .subscribe({
        next: responseAap => {
          this.aap = responseAap.body;
          this.isKpiProjetNeeded = this.kpiNeededFor(EnumScope.PROJET);
          this.isKpiStructureNeeded = this.kpiNeededFor(EnumScope.STRUCTURE);
          this.isAapClosed();
          this.getProjetDocuments();
          this.getProjetStructures();
          this.getCorrection();
          this.getDemandeDoc();
          this.updateGrillesImpactValidity(this.projet, this.aap);
          this.checkFicheDmdAideValidity();
          this.getExpensesStatus();
          this.setCanUserSeeContractPage();
          this.setIsUserSuiveurExterne();
          if (this.isUserSuiveurExterne) this.verifyAllSuiveurAprrovedDocs();
          this.infoValid = this.projetValidationFunction.infosGeneralesIsCorrect(this.projet);
          this.budgetValid = this.projetValidationFunction.isBudgetSet(this.projet);
          this.poleValid = this.projetValidationFunction.polesCompetitiviteIsCorrect(this.projet);
          this.secteursValid = this.projetValidationFunction.secteursApplicationIsCorrect(this.projet);
          this.domainesValid = this.projetValidationFunction.domainesTechnologiquesIsCorrect(this.projet);
          this.thematiqueValid = this.projetValidationFunction.thematiquesIsCorrect(this.projet);
          this.enqueteValid = this.projetValidationFunction.enqueteIsCorrect(this.projet);
          this.isFicheComValid = FicheComHelperService.isFicheComComplete(this.projet);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  setCanUserSeeContractPage(): void {
    this.contractualisationService
      .canUserSeeContractPage()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((canSee: boolean) => {
        this.canUserSeeContract = canSee;
      });
  }

  setIsUserSuiveurExterne(): void {
    this.contractualisationService.isUserSuiveurExterneInCurrentProject().subscribe((isSuiveur: boolean) => {
      this.isUserSuiveurExterne = isSuiveur;
    });
  }

  private kpiNeededFor(scope: EnumScope): boolean {
    return this.aap.kpis?.some(kpi => kpi.scope === scope);
  }

  updateGrillesImpactValidity(projet: Projet, aap: Aap) {
    if (!this.isKpiStructureNeeded && !this.isKpiProjetNeeded) {
      this.updateGIValidityModel(projet, aap);
      return;
    }

    this.grilleImpactHttpService
      .getProjectAndStructuresKpisStatus(this.projetId)
      .pipe(take(1))
      .subscribe({
        next: (statuts: GrilleImpactStatutModel) => this.updateGIValidityModel(projet, aap, statuts),
        error: (err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            this.updateGIValidityModel(projet, aap);
            return;
          }
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  checkFicheDmdAideValidity(): void {
    const fiche = this.aap.ficheDemandeAidePresent;
    if (!fiche || !fiche.active || fiche.etape !== this.etapeActuelle.nom) {
      this.isFicheDmdAideValid = true;
    } else {
      this.ficheDemandeAideService
        .getStatutsFichesDemandeAideByProjetId(this.projetId)
        .pipe(take(1))
        .subscribe({
          next: (resp: HttpResponse<FicheDemandeAideStatut[]>) => {
            this.isFicheDmdAideValid = resp.body.every(str => this.checkFicheDemandeAideNeededAndCompleted(str));
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  private checkFicheDemandeAideNeededAndCompleted(str: FicheDemandeAideStatut) {
    const structure = this.projet.structures.find(struct => struct.id === str.structureId);
    const budget =
      this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT ? structure.budgetPreDepot : structure.budgetDepot;
    return !budget?.besoin || str.statut === EnumStatutDemandeAide.COMPLETED;
  }

  getExpensesStatus(): void {
    this.depensesService
      .getProjetExpensesStatus(this.projetId)
      .pipe(take(1))
      .subscribe({
        next: (resp: HttpResponse<EnumValidation>) => {
          this.isExpensesProjetValid = resp.body === (this.enumValidation as any)[this.enumValidation.VALIDE];
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            return;
          }
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  getCorrection(): void {
    this.projetService.getCorrections(this.projet.id).subscribe(response => {
      this.demandesCorrection = response.body.filter(demande => demande.statut === 'OUVERT');
    });
  }

  getDemandeDoc(): void {
    this.projetService.getDemandeDocComplements(this.projet.id).subscribe(response => {
      this.demandesDocComplement = response.body.filter(demande => demande.statut === 'ENVOYEE' || demande.statut === 'COMPLETEE');
    });
  }

  /**
   * Set le tag du projet à "corrigé"
   */
  correctionSent(): void {
    this.projetService.getCorrections(this.projet.id).subscribe(response => {
      this.demandesCorrection = response.body.filter(demande => demande.statut === 'OUVERT');

      this.resetProjet();
    });
  }

  demandeComplementaireSent(): void {
    this.projetService.getDemandeDocComplements(this.projet.id).subscribe(response => {
      this.demandesDocComplement = response.body.filter(demande => demande.statut === 'ENVOYEE');

      this.resetProjet();
    });
  }

  resetProjet(): void {
    this.projetService.getProjetById(this.projetId).subscribe({
      next: result => {
        this.projet = result.body;
        this.projetService.setProjetObservable(this.projet);
      },
    });
  }

  getProjetStructures(): void {
    this.structureService
      .getStructuresByIdProject(this.projet.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        if (response) {
          this.structures = response.body.filter(structure => !structure.closed);
          this.checkIfStructuresHaveSelectedInformationBancaire();
          this.getUserAccess();
          this.checkIfStructuresHaveAtLeastOneSignataire();
          this.checkIfStructuresHavePrevisionsEconomiques();
          this.checkIfStructuresHaveDonneesFinancieres();
        }
      });
  }

  /*
   * Test si current user appartient a chef de file ou mandataire
   * */

  getUserAccess(): void {
    if (PermissionUtils.hasPermissionOnProjet(this.user, EnumPermissionUtilisateur.STRUCTURE_WRITE_ALL, this.projet)) {
      this.structureValid = this.checkStructures();
      this.nbStructuresToCheck = this.structures.length;
      for (const structure of this.structures) {
        this.checkExistingStructureDoc(structure, structure.role === EnumRoleStructure.MANDATAIRE);
      }
    } else if (PermissionUtils.hasPermissionOnProjet(this.user, EnumPermissionUtilisateur.STRUCTURE_WRITE, this.projet)) {
      this.currentUser = this.user.matricule;
      this.structures.forEach(structure => {
        if (structure.role === EnumRoleStructure.CANDIDAT) {
          structure.contacts.forEach(contact => {
            if (contact.matricule === this.currentUser) {
              this.nbStructuresToCheck = 1;
              this.structureValid = !this.sharedFunction.checkIncompleteCompany(structure, this.projet, this.aap);
              this.checkExistingStructureDoc(structure, structure.role === EnumRoleStructure.MANDATAIRE);
            }
          });
        }
      });
    }
    this.canWriteModification = PermissionUtils.hasPermissionOnProjet(this.user, EnumPermissionUtilisateur.PROJET_WRITE, this.projet);
    this.canWriteDemandeComplement = this.canWriteModification;
  }

  getProjetDocuments(): void {
    this.projetService
      .getDocumentsProjet(this.projet.id, [EnumTypeDocument.PROJET, EnumTypeDocument.MEDIA_VIDEO])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp: any) => {
        this.listDocumentProjet = resp.body;
        if (this.projet?.document?.nom) {
          this.listDocumentProjet.push(this.projet.document);
        }
        this.docValid = this.projetValidationFunction.documentsIsCorrect(this.aap, this.listDocumentProjet, this.projet);
      });
  }

  checkIfStructuresHavePrevisionsEconomiques(): void {
    if (this.featureFlagService.featureOff(EnumFeatureFlipping.STRUCTURE_PE_AF)) {
      this.previsionEconomiqueValid = true;
      return;
    }

    this.previsionEconomiqueValid =
      this.structures.filter(
        struct =>
          struct.role !== EnumRoleStructure.MANDATAIRE &&
          String(struct?.previsionsEconomiques?.statut) !== EnumValidation[EnumValidation.VALIDE]
      ).length === 0;
  }

  checkIfStructuresHaveDonneesFinancieres(): void {
    if (this.featureFlagService.featureOff(EnumFeatureFlipping.STRUCTURE_PE_AF)) {
      this.donneesFinancieresValid = true;
      return;
    }

    this.donneesFinancieresValid = this.structures.some(
      (structure: Structure) =>
        structure.role === EnumRoleStructure.MANDATAIRE &&
        String(structure?.donneesFinancieres?.statut) !== EnumValidation[EnumValidation.VALIDE]
    );
  }

  checkIfStructuresHaveSelectedInformationBancaire(): void {
    if (this.featureFlagService.featureOff(EnumFeatureFlipping.RIB_IBAN)) {
      return;
    }

    this.structures
      .filter(struct => this.structureValidation.structureNeedsToFillRibIban(this.projet, this.aap, struct))
      .forEach(structure => {
        this.informationsBancairesHttpService
          .getInformationsBancaires(structure.id)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(response => {
            const selectedInformationBancaire = response?.body?.find(informationBancaire => informationBancaire.selectionne);
            if (!selectedInformationBancaire) {
              this.isStructureInformationBancaireValid = false;
            }
          });
      });
  }

  checkIfProjetCanBeSend(): boolean {
    return (
      this.infoValid &&
      (this.budgetValid || !this.aap.budgetEstime) &&
      this.structureValid &&
      this.structureDocValid &&
      (!this.isMandataireExist || (this.mandataireValid && this.structureDocMandataireValid)) &&
      this.docValid &&
      this.checkSecteurs() &&
      this.checkDomaines() &&
      this.checkThematiques &&
      (this.poleValid || !this.aap.poleObligatoire) &&
      (this.enqueteValid || !this.aap.enqueteProjetPresent || !this.aap.enqueteProjetObligatoire) &&
      (this.isGrilleImpactsProjetValid || !this.isKpiProjetNeeded) &&
      this.isGrillesImpactsStructuresValid &&
      this.isFicheDmdAideValid &&
      this.checkInformationBancaire() &&
      this.isSignatairesValid
    );
  }

  checkSecteurs(): boolean {
    return this.secteursValid || !this.aap.secteursPresent || !this.aap.secteursObligatoire;
  }
  checkDomaines(): boolean {
    return this.domainesValid || !this.aap.domainePresent || !this.aap.domaineObligatoire;
  }
  checkThematiques(): boolean {
    return this.thematiqueValid || !this.aap.thematiquesPresent || !this.aap.thematiquesObligatoire;
  }

  checkInformationBancaire(): boolean {
    return this.isStructureInformationBancaireValid || this.aap.immersion;
  }

  checkExistingStructureDoc(struct: Structure, isMandataire: boolean): void {
    this.projetService
      .getDocumentsStructure(this.projet.id, struct.id, [EnumTypeDocument.STRUCTURE, EnumTypeDocument.STRUCTURE_ACTIONARIAL])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp: any) => {
        this.checkListDocStructure(struct, resp.body, isMandataire);
      });
  }

  checkListDocStructure(struct: Structure, listDocumentStructure: DocumentProjetInterface[], isMandataire: boolean): void {
    let listDoc = this.sharedFunction.getDocStructureAap(this.aap, struct, this.projet);
    let listDocActio = this.sharedFunction.getDocActioStructureAap(this.aap, struct, this.projet);

    listDoc = listDoc?.filter(document => {
      if (document.etapes[0] === this.sharedFunction.getProjectEtapeName(this.projet).toString()) {
        return document;
      }
    });
    listDocActio = listDocActio?.filter(document => {
      if (document.etapes[0] === this.sharedFunction.getProjectEtapeName(this.projet).toString()) {
        return document;
      }
    });

    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      this.checkDocValidPredepot(struct, listDocumentStructure, isMandataire, listDoc, listDocActio);
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      this.checkDocValidDepot(struct, listDocumentStructure, isMandataire, listDoc, listDocActio);
    }

    if (isMandataire && this.structureDocMandataireValid !== false) {
      this.structureDocMandataireValid = true;
    }

    if (this.structureDocValid !== false) {
      this.nbStructuresToCheck--;
      if (this.nbStructuresToCheck === 0) {
        this.structureDocValid = true;
      }
    }
  }

  checkDocValidPredepot(
    struct: Structure,
    listDocumentStructure: DocumentProjetInterface[],
    isMandataire: boolean,
    listDoc: any,
    listDocActio: any
  ): void {
    if (listDocumentStructure.length === 0 && (listDoc.length > 0 || listDocActio.length > 0)) {
      if (isMandataire) {
        this.structureDocMandataireValid = false;
      } else if (struct.budgetPreDepot?.besoin !== false) {
        this.structureDocValid = false;
      }
    } else {
      if (listDoc.length > 0 && struct.budgetPreDepot?.besoin) {
        this.checkDocStruct(listDocumentStructure);
      }

      if (listDocActio.length > 0 && struct.budgetPreDepot?.besoin) {
        this.checkDocStructActio(listDocumentStructure);
      }

      this.checkDocUnsafe(listDocumentStructure, isMandataire);
    }
  }

  checkDocValidDepot(
    struct: Structure,
    listDocumentStructure: DocumentProjetInterface[],
    isMandataire: boolean,
    listDoc: any,
    listDocActio: any
  ): void {
    if (listDocumentStructure.length === 0 && (listDoc.length > 0 || listDocActio.length > 0)) {
      if (isMandataire) {
        this.structureDocMandataireValid = false;
      } else if (struct.budgetDepot?.besoin !== false) {
        this.structureDocValid = false;
      }
    } else {
      if (listDoc.length > 0 && struct.budgetDepot?.besoin) {
        this.checkDocStruct(listDocumentStructure);
      }

      if (listDocActio.length > 0 && struct.budgetDepot?.besoin) {
        this.checkDocStructActio(listDocumentStructure);
      }

      this.checkDocUnsafe(listDocumentStructure, isMandataire);
    }
  }

  checkDocStruct(listDocumentStructure: DocumentProjetInterface[]): void {
    const listDocStruct = listDocumentStructure.filter((document: DocumentProjetInterface) => {
      if (document.scope === EnumScope.STRUCTURE && document.etape === this.sharedFunction.getProjectEtapeName(this.projet).toString()) {
        return document;
      }
    });
    if (listDocStruct.length === 0) {
      this.structureDocValid = false;
    }
  }

  checkDocStructActio(listDocumentStructure: DocumentProjetInterface[]): void {
    const listDocActioStruct = listDocumentStructure.filter((document: DocumentProjetInterface) => {
      if (
        document.scope === EnumScope.STRUCTURE_ACTIONARIAL &&
        document.etape === this.sharedFunction.getProjectEtapeName(this.projet).toString()
      ) {
        return document;
      }
    });
    if (listDocActioStruct.length === 0) {
      this.structureDocValid = false;
    }
  }

  checkDocUnsafe(listDocumentStructure: DocumentProjetInterface[], isMandataire: boolean) {
    for (const document of listDocumentStructure) {
      if (document.scan === EnumScanDocument.UNSAFE) {
        if (isMandataire) {
          this.structureDocMandataireValid = false;
        } else {
          this.structureDocValid = false;
        }
      }
    }
  }

  checkStructures(): boolean {
    if (this.structures.length === 0) {
      return false;
    }

    const structureExceptMandataire = this.structures.filter(structure => {
      if (structure.role !== EnumRoleStructure.MANDATAIRE) {
        return structure;
      } else {
        this.structureMandataireId = structure.id;
        this.isMandataireExist = true;
        this.checkExistingStructureDoc(structure, true);
        this.mandataireValid = !this.sharedFunction.checkIncompleteCompany(structure, this.projet, this.aap);
      }
    });

    if (structureExceptMandataire.length < 2 && this.projet.partenaireType === EnumTypePartenaire.MULTI) {
      return false;
    }

    const isChefFileExist = this.structures.some(structure => {
      return structure.role.toString() === EnumRoleStructure.CHEF_DE_FILE;
    });

    if (isChefFileExist) {
      const structuresIncompletes = this.structures.filter(structure => {
        if (structure.role.toString() !== EnumRoleStructure.MANDATAIRE) {
          return this.sharedFunction.checkIncompleteCompany(structure, this.projet, this.aap);
        }
      });

      if (structuresIncompletes.length === 0) {
        return true;
      }
    }
  }

  getEtapeName(): string {
    return EnumProjetEtape[this.etapeActuelle.nom];
  }

  sendProject(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Pré-dépôt',
        description: `<p>Vous allez pré-déposer votre projet<br>
                      Vous aurez la possibilité d’y apporter des modifications tant que la vérification
                      n’a pas débuté.</p>`,
        textGoButton: 'Valider mon pré-dépôt',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projetService
          .predeposeProjet([this.projet.id])
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: response => {
              this.projet = response.body[0];
              this.showToastrService.success('Votre projet a été transmis.');
              this.projetInfoService.updateStatutProjet(this.projet.statut);
              this.projetService.setProjetObservable(response.body[0]);

              this.checkNPSUser(EnumProjetEtape.PRE_DEPOT);
            },
            error: () => {
              this.showToastrService.error("Votre projet n'a pas pu être transmis, veuillez réessayer.");
            },
          });
      }
    });
  }
  sendProjectDepot(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Dépôt',
        description: `<p>Vous allez déposer votre projet<br>
                      Vous aurez la possibilité d’y apporter des modifications tant que la vérification
                      n’a pas débuté.</p>`,
        textGoButton: 'Valider mon dépôt',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projetService
          .deposeProjets([this.projet.id], this.projet)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (response: any) => {
              this.projet = response.body;
              this.showToastrService.success('Votre projet a été transmis.');
              this.projet.statut = EnumProjetStatut.ENVOYE;
              this.projetInfoService.updateStatutProjet(EnumProjetStatut.ENVOYE);

              this.checkNPSUser(EnumProjetEtape.DEPOT);
            },
            error: () => {
              this.showToastrService.error("Votre projet n'a pas pu être transmis, veuillez réessayer.");
            },
          });
      }
    });
  }

  checkNPSUser(etape: EnumProjetEtape): void {
    this.userService
      .getUserObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp: Utilisateur) => {
        const user = resp;
        const today = new Date(Date.now());
        const lastEval = new Date(user.lastNps);

        if (this.daysBetween(lastEval, today) > 30 || isNaN(this.daysBetween(lastEval, today))) {
          this.dialog.open(NpsModalComponent, {
            data: {
              title: 'Votre avis nous intéresse !',
              etape: etape,
              nombreProjets: UtilisateurUtils.getUserProjetsIds(user).length.toString(),
              role: UtilisateurUtils.getUserRoleOnProjet(user, this.projetId),
              textGoButton: 'Envoyer',
              textReturnButton: 'Ignorer',
            },
          });
        }
      });
  }

  daysBetween(StartDate, EndDate): number {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());

    // so it's safe to divide by 24 hours
    return (start - end) / oneDay;
  }

  /**
   * Ouvre une modale de confirmation de modification du projet
   * @param etape Etape du projet
   * @param dateInPopin Date de releve
   * @returns reference MatDialogRef de la modale
   */
  openModificationConfirmationDialog(etape: EnumProjetEtape, dateInPopin?: Date): MatDialogRef<ConfirmModalComponent, any> {
    let descriptionString;
    let data;

    if (etape === EnumProjetEtape.PRE_DEPOT) {
      descriptionString =
        '<p>Vous allez apporter des modifications à votre projet.<br>' +
        'Vous devrez de nouveau pré-déposer votre projet au plus tard le ' +
        this.datePipe.transform(dateInPopin, 'dd/MM/yyyy') +
        '.</p>';

      data = {
        title: 'Modifier le pré-dépôt',
        description: descriptionString,
        textGoButton: 'Modifier mon pré-dépôt',
        textReturnButton: 'Annuler',
      };
    } else {
      if (this.aap.typeWKF === EnumTypeWorkflow.WKF1) {
        descriptionString =
          '<p>Vous allez apporter des modifications à votre projet.<br>' +
          'Vous devrez de nouveau déposer votre projet pour que Bpifrance puisse le prendre en compte.</p>';
      } else {
        descriptionString =
          '<p>Vous allez apporter des modifications à votre projet.<br>' +
          'Vous devrez de nouveau déposer votre projet au plus tard le ' +
          this.datePipe.transform(dateInPopin, 'dd/MM/yyyy') +
          '.</p>';
      }

      data = {
        title: 'Modifier le dépôt',
        description: descriptionString,
        textGoButton: 'Modifier mon dépôt',
        textReturnButton: 'Annuler',
      };
    }

    return this.dialog.open(ConfirmModalComponent, {
      data,
    });
  }

  /**
   * Retourne la prochaine date de relève sinon la date de fermeture de l'aap
   * @returns prochaine date de releve
   */
  getNextDateReleve(): Date {
    const currentDate = new Date();
    let encours = false;
    let nextDateReleve;

    const sortDateReleve = this.aap.dateAutres;
    sortDateReleve.sort((b, a) => {
      return (new Date(b.valeur) as any) - (new Date(a.valeur) as any);
    });

    // get current releving date
    for (const date of sortDateReleve) {
      if (this.datePipe.transform(new Date(date.valeur), 'yyyy-MM-dd') >= this.datePipe.transform(currentDate, 'yyyy-MM-dd') && !encours) {
        encours = true;
        const testDate = new Date(date.valeur);
        nextDateReleve = testDate.toISOString();
      }
    }

    if (!nextDateReleve) {
      nextDateReleve = this.aap.dateFermeture;
    }

    return nextDateReleve;
  }

  /**
   * " l'étape reste à "Dépôt" et le statut du projet passe à "En Cours
   */
  deposeEnCoursProjet(): void {
    const dateInPopin = this.getNextDateReleve();
    const dialogRef = this.openModificationConfirmationDialog(EnumProjetEtape.DEPOT, dateInPopin);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projetService
          .modifierProjets([this.projetId], this.projet)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: response => {
              if (response) {
                this.projet = response.body[0];
                this.projetInfoService.updateStatutProjet(EnumProjetStatut.EN_COURS);
                this.projet.statut = EnumProjetStatut.EN_COURS;
              }
            },
            error: () => {
              this.showToastrService.error("Votre projet n'a pas pu être transmis, veuillez réessayer.");
            },
          });
      }
    });
  }

  updateDepositedProject(): void {
    const dateInPopin = this.getNextDateReleve();
    const dialogRef = this.openModificationConfirmationDialog(EnumProjetEtape.PRE_DEPOT, dateInPopin);

    dialogRef.afterClosed().subscribe(confirmed => {
      if (!confirmed) {
        return;
      }
      this.projetService
        .updateProjetStatut(this.projet.id, EnumProjetStatut.EN_COURS)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: HttpResponse<Projet>) => {
            this.projet = response.body;
            this.projetInfoService.updateStatutProjet(this.projet.statut);
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    });
  }

  /*
   * Vérifie si l'aap du projet est fermé
   * */
  isAapClosed(): void {
    this.aapClosed = this.aap.statut !== EnumAapStatut.OUVERT;
  }

  canUpdateClosedAapProject(): boolean {
    return this.aap.statut === EnumAapStatut.OUVERT || this.sharedFunction.isProjectDepotInAapFermeWKF1(this.aap, this.projet);
  }

  /*
   * Récupère la dernière étape du projet
   * */
  getEtapeProjet(): ProjetEtape {
    if (this.projet.etapes != null) {
      /* Classe les dates par ordre croissant afin de récupérer la plus récente */
      const datesEtapes = this.projet.etapes;
      datesEtapes.sort((b, a) => {
        return (new Date(b.date) as any) - (new Date(a.date) as any);
      });

      return datesEtapes[datesEtapes.length - 1];
    }

    return { nom: null, statut: null, date: null };
  }

  getEtapeNom(etape: ProjetEtape): string {
    if (etape) {
      return EnumProjetEtape.toString(etape.nom);
    } else {
      return '';
    }
  }

  /* Envoi sur la page des informations du projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  onGoToProjetInfoGene(): void {
    this.router.navigate(['../projet-info-general'], { relativeTo: this.route });
  }

  onGoToProjetInfoBudget(): void {
    this.router.navigate(['../projet-info-budget'], { relativeTo: this.route });
  }

  onGoToProjetPrevisionEconomique(): void {
    this.router.navigate(['../projet-consortium-previsions-economiques'], { relativeTo: this.route });
  }

  onGoToProjetInfoKpi(): void {
    this.router.navigate(['../projet-info-grille-impacts'], { relativeTo: this.route });
  }

  onGoToConsortium(): void {
    this.router.navigate(['../projet-consortium'], { relativeTo: this.route });
  }

  onGoToPolesInfo(): void {
    this.router.navigate(['../projet-info-poles'], { relativeTo: this.route });
  }

  onGoToProjetSecteurs(): void {
    this.router.navigate(['../projet-info-secteurs'], { relativeTo: this.route });
  }

  onGoToProjetDomaines(): void {
    this.router.navigate(['../projet-info-domaines'], { relativeTo: this.route });
  }

  onGoToProjetThematique(): void {
    this.router.navigate(['../projet-info-thematique'], { relativeTo: this.route });
  }

  onGoToProjetEnquete(): void {
    this.router.navigate(['../projet-info-enquete'], { relativeTo: this.route });
  }

  onGoToProjetInfoExpenses(): void {
    this.router.navigate(['../projet-info-depenses'], { relativeTo: this.route });
  }

  onGoToProjetInfoFicheCom(): void {
    this.router.navigate(['../projet-info-fiche-com'], { relativeTo: this.route });
  }

  onGoToStructureMandataire(): void {
    this.router.navigate(['../projet-consortium-info/' + this.structureMandataireId], { relativeTo: this.route });
  }

  onGoToContrat(): void {
    this.router.navigate(['../projet-contractualisation/signature-contrat'], { relativeTo: this.route });
  }

  /*
   * Récupère un message si aucune action n'est necessaire pour le statut et l'étape demandées
   * */
  aucuneActionMessage(): string {
    const etape = this.sharedFunction.getProjectEtapeName(this.projet);
    return (
      'Votre projet à l\'étape "' +
      EnumProjetEtape.toString(etape) +
      '" est au statut "' +
      EnumProjetStatut.toString(this.projet.statut, EnumProjetEtape.hasNomFeminin(etape)) +
      (this.isInfoTuile()
        ? '", il n\'y a plus aucune tâche à réaliser de votre côté.'
        : '", il n\'y a aucune tâche à réaliser de votre côté actuellement.')
    );
  }

  showAucuneActionMessage(): boolean {
    return (
      !this.sharedFunction.isProjectUpdatable(this.aap, this.projet) &&
      this.demandesCorrection.length === 0 &&
      this.projet.statut !== EnumProjetStatut.ENVOYE
    );
  }

  isInfoTuile(): boolean {
    return this.projet.statut === EnumProjetStatut.REJETE || this.projet.statut === EnumProjetStatut.TERMINE;
  }

  updateGIValidityModel(projet: Projet, aap: Aap, statuts?: GrilleImpactStatutModel) {
    if (statuts) {
      this.isGrillesImpactsStructuresValid = GrilleImpactHelperService.isStructuresGrillesImpactValid(projet, aap, statuts);
      this.isGrilleImpactsProjetValid = this.isKpiProjetNeeded && GrilleImpactHelperService.isGrilleImpactsValid(statuts?.statut);
    } else {
      this.isGrillesImpactsStructuresValid = !this.isKpiStructureNeeded;
      this.isGrilleImpactsProjetValid = !this.isKpiProjetNeeded;
    }
  }

  checkIfStructuresHaveAtLeastOneSignataire(): void {
    if (this.featureFlagService.featureOff(EnumFeatureFlipping.PARCOURS_SIGNATAIRES)) {
      return;
    }

    this.structures
      .filter(struct => struct.role !== EnumRoleStructure.MANDATAIRE)
      .forEach(structure => {
        this.structureService
          .getSignataires(structure.id)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(response => {
            const hasSignataire = response?.body?.length > 0;
            if (!hasSignataire) {
              this.isSignatairesValid = !this.budgetIsNeeded(structure);
            }
          });
      });
  }

  budgetIsNeeded(structure: Structure): boolean {
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      if (structure.budgetPreDepot) {
        return structure.budgetPreDepot?.besoin !== false;
      }
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      if (structure.budgetDepot) {
        return structure.budgetDepot?.besoin !== false;
      }
    }
    return true;
  }

  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly EnumProjetStatut = EnumProjetStatut;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly enumValidation = EnumValidation;
}
